<template>
  <el-dialog
    append-to-body
    width="1050px"
    :title="`${currRow.supplier_name}-评分详情`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    center
    >
    <el-table
      :data="table.data"
      border
      :loading="table.loading"
      :header-cell-style="{background:'#f5f5f5'}"
      style="width: 100%">
      <el-table-column
        prop="admin_name"
        label="评分人员">
      </el-table-column>
      <el-table-column
        prop="admin_type"
        label="身份">
        <template slot-scope="{row}">
          <span>{{toStr({1:'采购', 2:'仓管', 3:'财务'},row.admin_type)}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="type1"
        label="商品价格得分">
      </el-table-column>
      <el-table-column
        prop="type2"
        label="商品质量得分">
      </el-table-column>
      <el-table-column
        prop="type3"
        label="供应量得分">
      </el-table-column>
      <el-table-column
        prop="type4"
        label="送货效率得分">
      </el-table-column>
      <el-table-column
        prop="type5"
        label="售后服务得分">
      </el-table-column>
      <el-table-column
        prop="avg_score"
        label="平均分">
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="评分时间"
        width="140">
      </el-table-column>
    </el-table>
    <div class='pagination__wrap' v-if="table.total > 10" style="text-align:center;margin-top:10px;">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next"
        :current-page="table.params.page"
        :page-size="table.params.count"
        :total="table.total"
        :page-sizes="[10, 20, 50, 100, 150]"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </el-dialog>
</template>

<script>
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'PurchaseScoreDetail',
  mixins: [mixinTable],
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      visible: false,
      isChange: false,
      loading: false,
      table: {
        loading: true,
        params: {
          page:1,
          count:10
        },
        data: [],
        total: 0,
      },
      currRow: {}
    }
  },
  methods: {
    getDetail(row) {
      this.currRow = row;
      this.getTable();
    },
    getTable() {
      let _params = {
        page: this.table.params.page,
        count: this.table.params.count,
        month: this.currRow.selMonth,
        supplier_id: this.currRow.supplier_id,
      }
      this.$http.get("/admin/score/info",{params:_params}).then(res => {
        if(res.code == 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        }
      })
    },
    reset(done) {
      this.isChange = false;
      done && done()
    },
    toggle(show) {
      this.visible = show
      !show && this.reset()
    },
  }
}
</script>