<template>
  <div class="VTable__wrap">
    <div class="main" ref='main'>
      <el-table
        ref="multipleTable"
        border
        size="mini"
        v-loading="loading"
        highlight-current-row
        :default-expand-all="defaultExpandAll"
        row-key="id"
        :element-loading-text="loadingText"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0)"
        :data="data"
        :height="height"
        :header-cell-style="{background:'#f5f5f5'}"
        :key="Math.random()"
        @selection-change="handleSelectionChange"
        id="print"
      >
        <el-table-column
          v-if="showSelect"
          type="selection"
          :selectable="selectEnable"
          width="55">
        </el-table-column>
        <el-table-column
          v-if="showIndex"
          type="index"
          width="60"
          label="序号">
        </el-table-column>
        <el-table-column
          v-for="item in field"
          :key="item.name"
          :prop="item.name"
          :label="item.label"
          :width="item.width?item.width:''"
          :fixed="item.fixed"
          :show-overflow-tooltip="item.showTooltip"
        >
          <template slot-scope="{ row}">
            <slot :name="item.name" :row="row" >{{ row[item.name] }}</slot>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class='pagination__wrap' v-if='hasPagionation'>
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :current-page="page"
        :page-size="pageSize"
        :total="total"
        :page-sizes="[10, 20, 50, 100, 150]"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import { mixinTable } from "@/mixins/table.js";
  export default {
    name: 'VTable',
    mixins: [mixinTable],
    props: {
      title: { required: false, type: String, default: "" },
      addText: { required: false, type: String, default: "新增" },
      lazy: { required: false, type: Boolean, default: false },
      hasPagionation: { required: false, type: Boolean, default: false },
      showOverflowTooltip: { required: false, type: Boolean, default: true },
      exportText: { required: false, type: String, default: "导出" },
      loading: { type: Boolean, default: false },
      loadingText: { type: String, default: '拼命加载中' },
      auth: { required: false, type: Array, default: () => [] },
      field: { required: false, type: Array, default: () => [] },
      data: { type: Array, default: () => [] },
      page: { required: false, type: Number, default: 1 },
      pageSize: { required: false, type: Number, default: 10 },
      total: { required: false, type: Number, default: 0 },
      defaultExpandAll: { require: false, type:Boolean, default: false},
      showSelect: { required: false, type: Boolean, default: false },
      showIndex: { required: false, type: Boolean, default: false },
      selectAction: { required: false, type: Number, default: 1 },
    },
    data() {
      return {
        height: 0,
      }
    },
    methods: {
      handleSizeChange(pageSize) {
        this.$emit("handleSizeChange", pageSize);
      },
      handleCurrentChange(page) {
        this.$emit("handleCurrentChange", page)
      },
      // 批量选择
      handleSelectionChange(batchData) {
        this.$emit('handleSelectionChange', batchData)
      },
      selectEnable(row) {
        if(this.selectAction == 1) {
          // selectAction默认1时不需要禁用
          return true
        } else if(row.sum_lack_num == 0 && row.sorter_status != 3) {
          // sum_lack_num > 0 缺货的 和 分拣完成的禁用勾选框 （分拣时的逻辑）
          return true
        }
      },
      
    },
    mounted() {
      this.$nextTick(() => {
        this.height = this.$refs.main.offsetHeight || 500
      })
    },
  }
</script>
<style scoped lang="scss">
  .VTable__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 24px;
    background: #fff;
    .main {
      flex: 1;
      margin-bottom: 10px;
      height: 100%;
    }
    .pagination__wrap {
      text-align: right;
      padding: 0 50px 10px 0;
    }
  }
</style>

