<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="被评月份" prop="month">
          <el-date-picker
            v-model="table.params.month"
            type="month"
            value-format="yyyy-MM"
            format="yyyy-MM"
            @change="onSearch"
            :clearable="false"
            placeholder="选择月">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="供应商名称" prop="supplier_id">
          <el-select v-model="table.params.supplier_id" filterable id="bind_id" clearable placeholder="请输入" @focus="getSupplier" @change="onSearch">
            <el-option v-for="item in supplierArr" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset2">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd" style="display: flex;align-items: center;justify-content: space-between;">
        <div>
          更新时间：<span style="width:150px;display:inline-block;">{{table.change_time}}</span>  <span style="margin-left:30px;font-weight:bold;"> {{table.score_num}}</span>人评价
        </div>
        <div>
          <el-link :underline="false" href="http://h5delivery.cuntoubao.cn" target="_blank" style="margin: 0 10px;">
            <el-button :size="subUnitSize" type="warning" icon="el-icon-chat-line-round">去评价</el-button>
          </el-link>
          <el-button :size="subUnitSize" type="primary" icon="el-icon-edit" @click="showEdit">评价标准</el-button>
        </div>
      </div>
    </div>

    <div class="curTableWrap" ref="main">
      <el-table
        :data="table.data"
        ref="tableDom"
        border
        size="mini"
        style="width: 100%"
        v-loading="table.loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0)"
        :height="height"
        :header-cell-style="{background:'#f5f5f5'}"
        class="chTable"
      >
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column
          label="供应商名称"
          prop="supplier_name"
          :key="1">
        </el-table-column>
        <el-table-column
          label="本月供货商品项数"
          prop="goods_num"
          :key="2">
        </el-table-column>
        <el-table-column
          label="商品价格得分"
          prop="type_agv1"
          :key="3">
        </el-table-column>
        <el-table-column
          label="商品质量得分"
          prop="type_agv2"
          :key="4">
        </el-table-column>
        <el-table-column
          label="供应量得分"
          prop="type_agv3"
          :key="5">
        </el-table-column>
        <el-table-column
          label="送货效率得分"
          prop="type_agv4"
          :key="6">
        </el-table-column>
        <el-table-column
          label="售后服务得分"
          prop="type_agv5"
          :key="7">
        </el-table-column>
        <el-table-column
          label="本月得分"
          prop="month_score"
          sortable
          :sort-method="sortBySalesAmount"
          :key="8">
        </el-table-column>
        <el-table-column
          label="综合评分"
          prop="total_score"
          sortable
          :sort-method="sortBySalesCount"
          :key="9">
        </el-table-column>
        <el-table-column
          label="操作"
          prop="action"
          width="90px">
          <template slot-scope="{row}">
            <el-button type="text" size="mini" @click="showDetial(row)">查看打分</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class='pagination__wrap'>
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :current-page="table.params.page"
          :page-size="table.params.count"
          :total="table.total"
          :page-sizes="[10, 20, 50, 100, 150]"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 编辑 -->
    <edit ref="edit" @refresh="getTable"></edit>

    <!-- 详情 -->
    <detail ref="detail" ></detail>

  </div>
</template>

<script>
import Edit from './components/Edit.vue'
import Detail from './components/Detail.vue'
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
import { getStorage } from '@/storage'

export default {
  name: '',
  components: {
    Edit,
    Detail,
    VTable,
  },
  mixins: [mixinTable],
  data() {
    return {
      login_account: getStorage('account'),
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      height: 0,
      table: {
        loading: false,
        params: {
          month: '', //被评月份
          supplier_id: '', // 供应商ID
          page: 1,
          count: 20
        },
        data: [],
        total: 0,
        change_time: '', // 更新时间
        score_num: '', // 评价人数
      },
      orderCateArr: [], // 下单分类
      supplierArr: [], // 供应商
    
    }
  },
  created() {
    this.table.params.month = this.$moment(new Date()).format("YYYY-MM"); // 默认当前月份
    this.getTable();
  },
  mounted() {
    this.$nextTick(() => {
      this.height = this.$refs.main.offsetHeight || 500
    })
  },
  methods: {
    getTable() {
      this.table.loading = true;
      this.$http.get('/admin/score/list', {params:this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          this.table.change_time = res.data.change_time;
          this.table.score_num = res.data.score_num;
        }
      })
    },
    // 获取供应商
    getSupplier() {
      this.$http.get('/admin/supplier/list', {params:{page:1, count:10000}}).then(res => {
        if(res.code === 1) {
          this.supplierArr = res.data.list;
        }
      })
    },
    // 新增、编辑
    showEdit(row) {
      let dom = this.$refs.edit;
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 详情
    showDetial(row) {
      let dom = this.$refs.detail;
      dom.toggle(true)
      let obj = {
        ...row,
        selMonth: this.table.params.month, // 月份查询条件
      }
      dom.getDetail(obj)
      dom = null
    },
    // 删除
    remove(row) {
      this.setConfirm(
        `确认删除操作？`,
        {  id: row.id },
        "/admin/report/delete"
      );
    },
    sortBySalesAmount(a, b) {
      if ('ascending') {
        return Number(a.month_score) - Number(b.month_score);
      } else {
        return Number(b.month_score) - Number(a.month_score);
      }
    },
    sortBySalesCount(a, b) {
      if ('ascending') {
        return Number(a.total_score) - Number(b.total_score);
      } else {
        return Number(b.total_score) - Number(a.total_score);
      }
    }
  }
}
</script>

<style scoped>
.curTableWrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 24px;
  background: #fff;
  flex: 1;
  overflow-y: scroll;
}
.pagination__wrap {
  margin: 10px 0;
  text-align: right;
  /* padding-right: 40px; */
}
</style>
