<template>
  <div class="addPerson__wrap" v-show="visible">
    <el-header class="shadow page-header__wrap">
      <div class="flex">
        <div class="backBtn" @click="handleReturn">
          <el-image class="back-img" :src="require('@/assets/img/back.png')"></el-image>
          <span>返回</span>
        </div>
        <div class="title">
          <span class="site">当前位置：</span>
          {{ $route.meta.title }}
          <span> > </span>
          评价标准
        </div>
      </div>
    </el-header>

    <div class="main">
      <div class="mainBox">
        <el-table
          :data="table.data"
          border
          :loading="table.loading"
          :header-cell-style="{background:'#f5f5f5'}"
          @cell-mouse-enter="mouseOver"
          @cell-mouse-leave="mouseLeave"
          style="width: 100%">
          <el-table-column type="index" label="评分" width="120" fixed></el-table-column>
          <el-table-column
            prop="type1"
            label="商品价格">
            <template slot-scope="{row,$index,column}">
              <el-input
                v-if="row.istype1Selected"
                v-model="row.type1"
                @focus="focusEvent(row,$index,column)"
                @blur="blurEvent(row,$index,column)"
                maxlength="30"
                v-focus></el-input>
              <p @click="cellClick(row, column)" v-else style="cursor: pointer;">
                <span>{{row.type1}}</span>
                <i v-show="showTag == 'type1_' + row.score" class="el-icon-edit" style="color:#27cb16"></i>
              </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="type2"
            label="商品质量">
            <template slot-scope="{row,$index,column}">
              <el-input
                v-if="row.istype2Selected"
                v-model="row.type2"
                @focus="focusEvent(row,$index,column)"
                @blur="blurEvent(row,$index,column)"
                maxlength="30"
                v-focus></el-input>
              <p @click="cellClick(row, column)" v-else style="cursor: pointer;">
                <span>{{row.type2}}</span>
                <i v-show="showTag == 'type2_' + row.score" class="el-icon-edit" style="color:#27cb16"></i>
              </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="type3"
            label="供应量">
            <template slot-scope="{row,$index,column}">
              <el-input
                v-if="row.istype3Selected"
                v-model="row.type3"
                @focus="focusEvent(row,$index,column)"
                @blur="blurEvent(row,$index,column)"
                maxlength="30"
                v-focus></el-input>
              <p @click="cellClick(row, column)" v-else style="cursor: pointer;">
                <span>{{row.type3}}</span>
                <i v-show="showTag == 'type3_' + row.score" class="el-icon-edit" style="color:#27cb16"></i>
              </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="type4"
            label="送货效率">
            <template slot-scope="{row,$index,column}">
              <el-input
                v-if="row.istype4Selected"
                v-model="row.type4"
                @focus="focusEvent(row,$index,column)"
                @blur="blurEvent(row,$index,column)"
                maxlength="30"
                v-focus></el-input>
              <p @click="cellClick(row, column)" v-else style="cursor: pointer;">
                <span >{{row.type4}}</span>
                <i v-show="showTag == 'type4_' + row.score" class="el-icon-edit" style="color:#27cb16"></i>
              </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="type5"
            label="服务态度">
            <template slot-scope="{row,$index,column}">
              <el-input
                v-if="row.istype5Selected"
                v-model="row.type5"
                @focus="focusEvent(row,$index,column)"
                @blur="blurEvent(row,$index,column)"
                maxlength="30"
                v-focus></el-input>
              <p @click="cellClick(row, column)" v-else style="cursor: pointer;">
                <span>{{row.type5}}</span>
                <i v-show="showTag == 'type5_' + row.score" class="el-icon-edit" style="color:#27cb16"></i>
              </p>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
   
  </div>
</template>

<script>
  import { mixinTable } from "@/mixins/table.js";
  export default {
    name: 'purchaseScoreEdit',
    mixins: [mixinTable],
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        table: {
          loading: false,
          data: [],
          total:0,
        },
        showTag:"",
      }
    },
    // 自定义指令
    directives:{
      focus: {
        inserted:function(el){
          el.querySelector('input').focus();
          el.querySelector('input').select();
        }
      }
    },
    methods: {
      getDetail(row) {
        this.$http.get('/admin/score/norm', { params:{}}).then(res => {
          if(res.code == 1) {
            this.table.data = res.data.list.map((item,key) => {
              return {
                ...item,
                istype1Selected: false,
                istype2Selected: false,
                istype3Selected: false,
                istype4Selected: false,
                istype5Selected: false,
              }
            });
          }
        })
      },
      focusEvent(row,index,column){
        if(column.label =='商品价格'){
          row.oldType1 = row.type1
        }else if(column.label=='商品质量'){
          row.oldType2 = row.type2
        }else if(column.label =='供应量'){
          row.oldType3 = row.type3
        }else if(column.label =='送货效率'){
          row.oldType4 = row.type4
        }else if(column.label =='服务态度'){
          row.oldType5 = row.type5
        }
      },
      cellClick(row,column){
        if(column.label =='商品价格'){
          row.istype1Selected = !row.istype1Selected;
        } else if(column.label =='商品质量'){
          row.istype2Selected = !row.istype2Selected;
        } else if(column.label =='供应量'){
          row.istype3Selected = !row.istype3Selected;
        } else if(column.label =='送货效率'){
          row.istype4Selected = !row.istype4Selected;
        } else if(column.label =='服务态度'){
          row.istype5Selected = !row.istype5Selected;
        }
      },

      blurEvent(row,curIndex,column){
        console.log(row,"row")
        console.log(curIndex,"curIndex")
        console.log(column,"column")
        if(column.label =='商品价格'){
          if(!row.type1) {
            this.$message.warning("内容不能为空")
            return
          }
          row.istype1Selected = !row.istype1Selected;
          if(row.type1 !== row.oldType1){
            let score = [{
              type: 1, //类型 1:商品价格 2:商品质量 3:供应量 4:送货效率 5:服务态度
              value: row.score, //分值 1-5分
              msg: row.type1 //选项内容
            }]             
            this.editFun(score)
          }
        } else if(column.label =='商品质量'){
          if(!row.type2) {
            this.$message.warning("内容不能为空")
            return
          }
          row.istype2Selected = !row.istype2Selected;
          if(row.type2 !== row.oldType2){
            let score = [{
              type: 2, //类型 1:商品价格 2:商品质量 3:供应量 4:送货效率 5:服务态度
              value: row.score, //分值 1-5分
              msg: row.type2 //选项内容
            }]             
            this.editFun(score)
          }
        } else if(column.label =='供应量'){
          if(!row.type3) {
            this.$message.warning("内容不能为空")
            return
          }
          row.istype3Selected = !row.istype3Selected;
          if(row.type3 !== row.oldType3){
            let score = [{
              type: 3, //类型 1:商品价格 2:商品质量 3:供应量 4:送货效率 5:服务态度
              value: row.score, //分值 1-5分
              msg: row.type3 //选项内容
            }]             
            this.editFun(score)
          }
        } else if(column.label =='送货效率'){
          if(!row.type4) {
            this.$message.warning("内容不能为空")
            return
          }
          row.istype4Selected = !row.istype4Selected;
          if(row.type4 !== row.oldType4){
            let score = [{
              type: 4, //类型 1:商品价格 2:商品质量 3:供应量 4:送货效率 5:服务态度
              value: row.score, //分值 1-5分
              msg: row.type4 //选项内容
            }]             
            this.editFun(score)
          }
        } else if(column.label =='服务态度'){
          if(!row.type5) {
            this.$message.warning("内容不能为空")
            return
          }
          row.istype5Selected = !row.istype5Selected;
          if(row.type5 !== row.oldType5){
            let score = [{
              type: 5, //类型 1:商品价格 2:商品质量 3:供应量 4:送货效率 5:服务态度
              value: row.score, //分值 1-5分
              msg: row.type5 //选项内容
            }]             
            this.editFun(score)
          }
        }
      },
      // 保存方法
      editFun(arr) {
        let _params ={
          score: arr
        }
        this.$http.post('/admin/score/setNorm', _params).then(res => {
          if(res.code == 1) {
            this.$message({
              message:"修改成功",
              type:"success",
              duration:1000,
            })
          }
        })
      },
      // 鼠标移入
      mouseOver(row, column, cell, event) {
        const key = column.property + "_" + row.score;
        this.showTag = key;
      },
      // 鼠标移出
      mouseLeave(row, column, cell, event) {
        this.showTag = '';
      },

      reset(done) {
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 返回
      handleReturn() {
        this.$emit("refresh")
        this.toggle(false)
      }

    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
} 
.addPerson__wrap .main {
  overflow-x: hidden;
  background: #fff;
}
.returnBtn {
  z-index: 99;
  box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
}
.footerWrap {
  text-align: center;
  padding: 10px;
   box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
  z-index: 99;
}

</style>
